<template>
  <span :class="$style.pointerIcon">
    <SvgIcon icon="mouse-pointer" stroke-width="BOLD" />
  </span>
</template>
<script lang="ts" setup></script>

<style module lang="scss">
:root {
  --pointer-icon-size: 47px;
}

.pointerIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  width: var(--pointer-icon-size);
  height: var(--pointer-icon-size);
  color: $white;
  background-color: $ochre-500;
  border-radius: 50%;
  transition:
    background-color $default-transition-duration,
    color $default-transition-duration;

  svg {
    width: 68%;
    height: 68%;
  }
}
</style>
