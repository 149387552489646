<template>
  <div :class="$style.videoCarousel" class="container-md px-s px-md-0 pb-ml pb-md-l pt-ml pt-lg-l">
    <div :class="$style.wrapper">
      <div class="d-flex flex-column gap-sm gap-md-m justify-content-start">
        <div class="flex-grow-0" :class="$style.videos">
          <TransitionGroup
            :enter-active-class="$style.slideActive"
            :leave-active-class="$style.slideActive"
            :enter-from-class="$style.slideFrom"
            :leave-to-class="$style.slideTo"
          >
            <template v-for="(video, index) in videos" :key="video">
              <div v-if="index === currentItemIndex" :class="$style.videoWrapper">
                <BackgroundVideo muted autoplay :class="$style.video" :video="video" @ended="videoEnded" />
              </div>
            </template>
          </TransitionGroup>
        </div>
        <div class="d-flex gap-xs justify-content-center">
          <div
            v-for="(video, index) in videos"
            :key="video"
            :class="[$style.bubble, { [$style.active]: index === currentItemIndex }]"
            @click="currentItemIndex = index"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useCycleList } from "@vueuse/core";
import BackgroundVideo from "../BackgroundVideo.vue";

const props = withDefaults(
  defineProps<{
    videos: string[];
  }>(),
  {},
);

const { next, index: currentItemIndex } = useCycleList(new Array(props.videos.length).fill(0).map((_, i) => i));

const videoEnded = () => {
  next();
};
</script>

<style module lang="scss">
.slideActive {
  transition: all 0.75s ease;
}

.slideFrom {
  opacity: 0;
  transform: translateX(100%);
}

.slideTo {
  opacity: 0;
  transform: translateX(-100%);
}

.videoCarousel {
  --carousel-video-size: 100%;

  .wrapper {
    width: var(--carousel-video-size);

    @include media-breakpoint-up(md) {
      --carousel-video-size: 416px;
    }

    @include media-breakpoint-up(lg) {
      --carousel-video-size: 468px;
    }
  }

  .videos {
    overflow: hidden;
    position: relative;
    width: 100%;
    aspect-ratio: 1/1;
    border: solid 1px $purple-300;
    border-radius: $default-border-radius;
  }

  .videoWrapper {
    position: absolute;
    width: 100%;
    height: 100%;
    .video {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }

  .bubble {
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background-color: rgba($grey-900, 0.1);
    transition: background-color 0.3s ease-in-out;

    &.active {
      background-color: $purple-700;
    }
  }
}
</style>
