<template>
  <li :class="$style.item">
    <slot />
  </li>
</template>

<script lang="ts" setup></script>

<style module lang="scss">
.item {
  @extend %text-three-white;
  position: relative;
  padding-left: 80px;
  display: flex;
  min-height: 64px;
  align-items: top;

  &::before {
    @extend %label-one-ochre-500;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    background-color: $purple-700;
    justify-content: center;
    align-items: center;
  }
}
</style>
