<template>
  <div id="mc_embed_signup">
    <form
      id="mc-embedded-subscribe-form"
      :action="action"
      method="post"
      name="mc-embedded-subscribe-form"
      class="validate"
      target="_blank"
    >
      <div id="mc_embed_signup_scroll" class="d-flex flex-column gap-sm">
        <div class="mc-field-group">
          <InputText label="E-Mail*" :has-value="!!formContents.email">
            <input
              id="mce-EMAIL"
              v-model="formContents.email"
              type="email"
              name="EMAIL"
              required
              class="required email"
            />
          </InputText>
        </div>
        <div class="mc-field-group">
          <InputText label="Vorname" :has-value="!!formContents.firstName">
            <input id="mce-FNAME" v-model="formContents.firstName" type="text" name="FNAME" />
          </InputText>
        </div>
        <div class="mc-field-group">
          <InputText label="Nachname" :has-value="!!formContents.lastName">
            <input id="mce-LNAME" v-model="formContents.lastName" type="text" name="LNAME" />
          </InputText>
        </div>
        <div hidden="true">
          <input type="hidden" name="tags" :value="tagsList" />
        </div>
        <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups-->
        <div style="position: absolute; left: -5000px" aria-hidden="true">
          <input type="text" name="b_009663a167f968585bbc71572_be89b6ad32" tabindex="-1" value="" />
        </div>
        <div class="mt-ml optionalParent">
          <div class="clear foot">
            <BubbleText id="mc-embedded-subscribe" pointer>
              <input :class="$style.submit" type="submit" value="SENDEN" name="subscribe" />
            </BubbleText>
          </div>
        </div>
      </div>
    </form>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from "vue";

import InputText from "../form/InputText.vue";
import BubbleText from "../BubbleText.vue";

const props = defineProps<{
  action: string;
  tags: string[];
}>();

const formContents = ref({
  email: "",
  firstName: "",
  lastName: "",
});

const tagsList = computed(() => {
  return props.tags.toString();
});
</script>

<style module lang="scss">
.submit {
  border: none;
  background: none;
  color: inherit;
}
</style>
