<template>
  <AnchorBox :id="slugify(component.name ?? '')" v-if="component.__typename === 'AnchorBlock'"></AnchorBox>

  <VideoJourney :data="component" v-else-if="component.__typename === 'VideoJourneyBlock'"></VideoJourney>

  <LeadBox v-else-if="component.__typename === 'LeadBoxBlock'">
    <span class="richtext" v-html="getHtmlFromLexicalJson(component.text)"></span>
    <div class="container-md px-0 mb-ml mb-md-0 mt-ml mt-lg-l d-flex gap-ml gap-md-l flex-wrap flex-md-nowrap">
      <template v-for="linkComponent in component.links">
        <ContentComponentRenderer :component="linkComponent" />
      </template>
    </div>
  </LeadBox>

  <template v-else-if="component.__typename === 'CaseLeadBoxBlock'">
    <SpacerBox class="pt-l" />
    <CaseLeadBox :video="component.video ?? ''" :poster="component.poster ?? undefined">
      <template #headline>
        <span :class="$style.preLineBreak">
          {{ component.headline }}
        </span>
      </template>
      <template #text>
        <div class="richtext" v-html="getHtmlFromLexicalJson(component.text)"></div>
      </template>
    </CaseLeadBox>
  </template>

  <div
    v-else-if="component.__typename === 'BubbleTextBlock'"
    :class="root ? 'container-md px-s px-md-0 mb-l mb-md-xl mb-lg-xl' : undefined"
  >
    <BubbleText
      @click="component.modal?.length ? openModal(component.modal[0]) : undefined"
      :pointer="!!component.url || !!component.page || !!component.modal?.length"
      :url="
        component.url ||
        (component.page?.slug !== null && component.page?.slug !== undefined ? `/${component.page?.slug}` : undefined)
      "
      :target="component.openInNewTab ? '_blank' : undefined"
    >
      <span :class="$style.preLineBreak">{{ component.bubbleText }}</span>
    </BubbleText>
  </div>

  <TitleTextBox
    :title="component.title ?? undefined"
    :first-letter="!!component.emphasizeFirstLetter"
    v-else-if="component.__typename === 'TitleTextBoxBlock'"
  >
    <span class="richtext" v-html="getHtmlFromLexicalJson(component.text)"></span>
  </TitleTextBox>

  <AngledTextBox :title="component.title ?? undefined" v-else-if="component.__typename === 'AngledTextBoxBlock'">
    <span class="richtext" v-html="getHtmlFromLexicalJson(component.text)"></span>
  </AngledTextBox>

  <PurposeBox
    :icon="component.icon ?? undefined"
    :description="component.description ?? undefined"
    v-else-if="component.__typename === 'PurposeBoxBlock'"
  >
    <span class="richtext" v-html="getHtmlFromLexicalJson(component.text)"></span>
  </PurposeBox>

  <VideoCarousel
    :videos="component.videos?.map((v) => v.video ?? '') ?? []"
    v-else-if="component.__typename === 'VideoCarouselBlock'"
  ></VideoCarousel>

  <DoubleSlotBox :icon="component.contentRight?.icon ?? ''" v-else-if="component.__typename === 'DoubleSlotBoxBlock'">
    <template #left>
      <div class="container-md px-s px-md-0 container-lg-fluid mb-l mb-lg-0">
        <template v-for="block in component.contentLeft?.blocks">
          <ContentComponentRenderer :component="block" />
        </template>
      </div>
    </template>
    <span class="richtext" v-html="getHtmlFromLexicalJson(component.contentRight?.text)"></span>
  </DoubleSlotBox>

  <QuotationBox :author="component.author ?? ''" v-else-if="component.__typename === 'QuotationBoxBlock'">
    <span class="richtext" v-html="getHtmlFromLexicalJson(component.text)"></span>
  </QuotationBox>

  <ReviewQuotationBox
    :image="component.image ?? undefined"
    :author="component.author ?? ''"
    v-else-if="component.__typename === 'ReviewQuotationBoxBlock'"
  >
    <span class="richtext" v-html="getHtmlFromLexicalJson(component.text)"></span>
  </ReviewQuotationBox>

  <IconCardGrid :title="component.title" v-else-if="component.__typename === 'IconCards'">
    <IconCard :title="card.title ?? undefined" :icon="card.icon ?? undefined" v-for="card in component.cards">
      <span class="richtext" v-html="getHtmlFromLexicalJson(card.text)"></span>
      <div v-if="card.additionalContent?.length" class="pt-sm pt-lg-ml mb-sm">
        <ContentComponentRenderer
          v-for="additionalContent in card.additionalContent"
          :key="additionalContent.id ?? ''"
          :component="additionalContent"
        />
      </div>
    </IconCard>
  </IconCardGrid>

  <div v-else-if="component.__typename === 'PurposeGridBlock'">
    <PurposeGrid :title="component.title">
      <PurposeStep
        v-for="(step, i) in component.steps"
        :icon="step.icon ?? ''"
        :title="step.title ?? ''"
        :video="step.video ?? ''"
        :step="i + 1"
      >
        <span class="richtext" v-html="getHtmlFromLexicalJson(step.text)"></span>
        <div v-if="step.additionalContent?.length" class="pt-sm pt-lg-ml mb-ml mb-lg-ml">
          <ContentComponentRenderer
            v-for="additionalContent in step.additionalContent"
            :key="additionalContent.id ?? ''"
            :component="additionalContent"
          />
        </div>
      </PurposeStep>
    </PurposeGrid>
    <PurposeFooter :url="component.footer.url ?? ''" v-if="component.showFooter && component.footer">
      {{ component.footer.footerText }}
      <template #linkLabel>
        {{ component.footer.linkLabel }}
      </template>
    </PurposeFooter>
  </div>

  <CasesBox v-else-if="component.__typename === 'CasesBoxBlock'">
    <CaseBox :image="customerCase.image" :path="customerCase.page?.slug ?? ''" v-for="customerCase in component.cases">
      <template #title>
        {{ customerCase.title }}
      </template>
      <template #description>
        {{ customerCase.description }}
      </template>
      <span class="richtext" v-html="getHtmlFromLexicalJson(customerCase.text)"></span>
    </CaseBox>
  </CasesBox>

  <LeifLink :url="component.url ?? ''" v-else-if="component.__typename === 'LeifLinkBlock'">
    <span class="richtext" v-html="getHtmlFromLexicalJson(component.text)"></span>
  </LeifLink>

  <div v-else-if="component.__typename === 'MediaBlock'" class="container-md px-s px-md-0 mb-ml mb-md-l mb-lg-xl">
    <SizedImage
      class="w-100"
      v-if="component.media"
      :image="component.media"
      :sizes="[
        ['md', 'xs'],
        ['lg', 'md'],
        [null, 'lg'],
      ]"
    />
  </div>

  <ImageHeader :data="component" v-else-if="component.__typename === 'ImageHeaderBlock'" />
</template>

<script lang="ts" setup>
import { useModalStore } from "vue-haystack";
import AnchorBox from "~/components/content/AnchorBox.vue";
import AngledTextBox from "~/components/content/AngledTextBox.vue";
import CaseLeadBox from "~/components/content/CaseLeadBox.vue";
import ImageHeader from "~/components/content/ImageHeader.vue";
import LeifLink from "~/components/content/LeifLink.vue";
import PurposeBox from "~/components/content/PurposeBox.vue";
import ReviewQuotationBox from "~/components/content/ReviewQuotationBox.vue";
import SizedImage from "~/components/content/SizedImage.vue";
import SpacerBox from "~/components/content/SpacerBox.vue";
import MailchimpSignupModal from "~/components/modals/MailchimpSignupModal.vue";
import NumberedItemsModal from "~/components/modals/NumberedItemsModal.vue";
import TextGridModal from "~/components/modals/TextGridModal.vue";
import { getHtmlFromLexicalJson } from "~/utils/getHtmlFromLexicalJson";
import { type PageBlockFragment } from "../graphql/graphql";
import { slugify } from "pulls-website-core";
import { type BubbleTextModal } from "../types/modals";
import BubbleText from "./BubbleText.vue";
import CaseBox from "./content/CaseBox.vue";
import CasesBox from "./content/CasesBox.vue";
import DoubleSlotBox from "./content/DoubleSlotBox.vue";
import IconCard from "./content/IconCard.vue";
import IconCardGrid from "./content/IconCardGrid.vue";
import LeadBox from "./content/LeadBox.vue";
import PurposeFooter from "./content/PurposeFooter.vue";
import PurposeGrid from "./content/PurposeGrid.vue";
import PurposeStep from "./content/PurposeStep.vue";
import QuotationBox from "./content/QuotationBox.vue";
import TitleTextBox from "./content/TitleTextBox.vue";
import VideoCarousel from "./content/VideoCarousel.vue";
import VideoJourney from "./content/VideoJourney.vue";

const props = withDefaults(
  defineProps<{
    component: PageBlockFragment;
    root?: boolean;
  }>(),
  {
    root: false,
  },
);

const modalStore = useModalStore();

function openModal(modalData: BubbleTextModal) {
  if (modalData.__typename === "MailchimpSignupModal") {
    modalStore.push(MailchimpSignupModal, {
      data: modalData,
    });
  } else if (modalData.__typename === "NumberedItemsModal") {
    modalStore.push(NumberedItemsModal, {
      data: modalData,
    });
  } else if (modalData.__typename === "TextGridModal") {
    modalStore.push(TextGridModal, {
      data: modalData,
    });
  }
}
</script>

<style module lang="scss">
.preLineBreak {
  white-space: pre-line;
}
</style>
