<template>
  <NuxtLink :custom="!url" :class="$style.nuxtLink" :to="url" :target="target">
    <component :is="tag" :class="$style.bubbleText" :role="pointer ? 'button' : ''" v-bind="!url ? $attrs : undefined">
      <span class="px-xs" :class="[$style.text, { [$style.isClickable]: hasPointer }]">
        <slot />
        <span v-if="hasPointer" :class="$style.pointerWrapper">
          <PointerIcon :class="$style.pointerIcon" />
        </span>
      </span>
    </component>
  </NuxtLink>
</template>

<script lang="ts" setup>
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    url?: string;
    target?: string;
    pointer?: boolean;
    tag?: string;
  }>(),
  {
    url: undefined,
    target: undefined,
    pointer: false,
    tag: "span",
  },
);

const hasPointer = computed(() => {
  return !!(props.pointer || props.url);
});
</script>

<style module lang="scss">
:root {
  --bubble-text-background: #{$purple-700};
  --bubble-text-color: #{$white};
}
.nuxtLink {
  display: inline-block;
  text-decoration: none;
}
.bubbleText {
  position: relative;
  display: inline-block;
  @extend %title-two-uppercase-white;
}
.text {
  background-color: var(--bubble-text-background);
  border-radius: $default-border-radius;
  box-decoration-break: clone;
  line-height: map-get($spacers-map, "m");
  color: var(--bubble-text-color);
  transition: color $default-transition-duration;

  div,
  p {
    display: contents;
  }

  &.isClickable:hover {
    color: $ochre-500;

    .pointerIcon {
      background-color: $purple-700;
    }
  }

  @include media-breakpoint-up(lg) {
    line-height: 40px;
  }
}
.pointerWrapper {
  position: relative;
  display: inline-block;
  width: 0px;
  height: 0px;
  line-height: 0;
  font-size: 0;
  overflow: visible;

  .pointerIcon {
    --pointer-icon-size: 32px;
    position: absolute;
    transform: translate(0px, 0);

    @include media-breakpoint-up(lg) {
      --pointer-icon-size: 48px;
      transform: translate(-2px, -2px);
    }
  }
}
</style>
