<template>
  <div :class="$style.baseModal">
    <div :class="$style.modal" class="d-flex flex-column">
      <div :class="$style.topBar" class="mb-sm">
        <SvgIcon size="35px" :class="$style.closeButton" role="button" icon="x" @click="modal.close()" />
      </div>
      <div class="p-s pb-ml p-md-sm" :class="$style.content">
        <slot />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { useModal } from "vue-haystack";

const modal = useModal();
</script>

<style module lang="scss">
.baseModal {
  width: 100%;
  height: 100%;
  transform: translate(-50%) !important;
  --modal-spacing: 5px;
}
.modal {
  position: absolute;
  background-color: $purple-900;
  top: 0;
  left: var(--modal-spacing);
  right: var(--modal-spacing);
  transform: translateY(-50%);
  max-height: calc(100vh - 10px);
  border-radius: $default-border-radius;

  @include media-breakpoint-up(md) {
    --modal-spacing: 10px;
    max-height: calc(100vh - 20px);
  }

  @include media-breakpoint-up(xl) {
    --modal-spacing: #{map-get($spacers-map, "ml")};
    max-height: calc(100vh - #{map-get($map: $spacers-map, $key: "ml")} * 2);
  }

  .topBar {
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: map-get($map: $spacers-map, $key: "xs");
    right: map-get($map: $spacers-map, $key: "xs");

    @include media-breakpoint-up(md) {
      position: absolute;
      top: map-get($map: $spacers-map, $key: "sm");
      right: map-get($map: $spacers-map, $key: "sm");
    }

    .closeButton {
      color: $ochre-500;

      &:hover {
        color: $ochre-300;
      }

      &:active {
        color: $ochre-700;
      }
    }
  }

  .content {
    overflow: auto;
  }
}
</style>
