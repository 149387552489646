<template>
  <div :class="$style.videoJourney" class="d-flex flex-column justify-content-end pe-auto">
    <ClientOnly>
      <BackgroundVideo
        ref="videoRef"
        play-on-load
        muted
        :class="$style.video"
        :video="currentItem.video"
        @ended="videoEnded"
        @loadstart="videoLoadStart"
        @canplay="canplay"
      />
    </ClientOnly>
    <div :class="$style.overlay"></div>
    <div
      :class="$style.content"
      class="d-flex flex-column justify-content-end container-md px-s px-md-0 mb-sm mb-ml"
      @click="handleVideoClick"
    >
      <BubbleText class="mb-s">{{ data.bubbleText }}</BubbleText>
      <div :class="$style.titleGrid">
        <Transition
          mode="out-in"
          :enter-active-class="$style.fadeActive"
          :leave-active-class="$style.fadeActive"
          :enter-from-class="$style.fadeFrom"
          :leave-to-class="$style.fadeFrom"
        >
          <h1 :key="currentItem.title" class="mb-m" :class="$style.title">
            {{ currentItem.title }}
          </h1>
        </Transition>
      </div>

      <div class="gap-s" :class="$style.progress">
        <template v-for="(item, index) in items" :key="item.label">
          <Transition
            mode="out-in"
            :enter-active-class="$style.fadeActive"
            :leave-active-class="$style.fadeActive"
            :enter-from-class="$style.fadeFrom"
            :leave-to-class="$style.fadeFrom"
          >
            <div
              v-if="index <= currentItemIndex"
              :class="[$style.item, { [$style.currentItem]: index === currentItemIndex }]"
              class="flex-grow-1"
            >
              <div class="mb-xs d-flex gap-xs align-items-center">
                <ProgressBubble :class="$style.progressIcon" icon="heart">
                  <template v-if="index > 0">{{ index }}</template>
                </ProgressBubble>
                <div
                  :style="{
                    '--line-progress': index === currentItemIndex ? progressPercentage : 1,
                  }"
                  class="flex-shrink-1 flex-grow-1"
                  :class="$style.line"
                ></div>
              </div>
              <div :class="$style.label">
                {{ item.label }}
              </div>
            </div>
          </Transition>
        </template>
      </div>
    </div>
    <div></div>
  </div>
</template>

<script lang="ts" setup>
import { useCycleList, useIntervalFn } from "@vueuse/core";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";

import BackgroundVideo from "../BackgroundVideo.vue";
import BubbleText from "../BubbleText.vue";
import ProgressBubble from "../ProgressBubble.vue";
import type { PageBlockFragment } from "../../graphql/graphql";
import { assertIsDefined } from "pulls-website-core";

type VideoJourneyBlock = PageBlockFragment & { __typename: "VideoJourneyBlock" };

const props = withDefaults(
  defineProps<{
    data: VideoJourneyBlock;
  }>(),
  {},
);

const router = useRouter();
const videoRef = ref<InstanceType<typeof BackgroundVideo>>();
const progressSecs = ref(0);
const durationSecs = ref(0);

const items = computed(
  () =>
    props.data.items?.map((i) => {
      assertIsDefined(i.title, "title");
      assertIsDefined(i.label, "label");
      assertIsDefined(i.video, "video");
      assertIsDefined(i.navigateTo, "navigateTo");

      return {
        title: i.title,
        label: i.label,
        video: i.video,
        slug: i.navigateTo,
      };
    }) ?? [],
);
const { next, index: currentItemIndex, state: currentItem } = useCycleList(items.value);

const progressPercentage = computed(() => (durationSecs.value ? progressSecs.value / durationSecs.value : 0));

const videoLoadStart = () => {
  progressSecs.value = 0;
};

const videoEnded = () => {
  next();
};

useIntervalFn(() => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-call
  progressSecs.value = videoRef.value?.getCurrentTime() ?? 0;
}, 50);

const canplay = () => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-member-access
  durationSecs.value = videoRef.value?.el?.duration ?? 0;
};

const handleVideoClick = async () => {
  await router.push({
    hash: `#${items.value[currentItemIndex.value].slug}`,
  });
};
</script>

<style module lang="scss">
.fadeActive {
  transition: opacity 0.75s ease;
}

.fadeFrom {
  opacity: 0;
}

.videoJourney {
  display: block;
  position: relative;
  width: 100%;
  height: calc(100dvh - var(--outer-border-width) * 1);
  border-radius: $default-border-radius;
  background-color: $purple-900;
  margin-bottom: var(--outer-border-width);
  overflow: hidden;
  border-radius: $default-border-radius;
  text-decoration: none;

  .video {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    pointer-events: none;
    background-color: rgba($black-one, 0.2);
  }

  .content {
    cursor: pointer;
    z-index: 1;

    .titleGrid {
      display: grid;
      grid-template-columns: 1fr;

      @include media-breakpoint-up(md) {
        grid-template-columns: 10fr 2fr;
      }

      .title {
        @extend %title-one-900-white;
      }
    }

    .progress {
      display: grid;
      grid-template-columns: repeat(4, 1fr);

      .item {
        flex-basis: 0;
        grid-column: unset;
        display: none;

        @include media-breakpoint-up(lg) {
          display: initial;
        }
      }
      .currentItem {
        grid-column: 1/-1;
        display: initial;

        @include media-breakpoint-up(lg) {
          grid-column: unset;
        }
      }

      .progressIcon {
        width: 24px;

        @include media-breakpoint-up(lg) {
          width: 32px;
        }
      }

      .label {
        @extend %text-three-900-white;
      }

      .line {
        height: 1px;
        background-color: rgba($white, 0.25);
        position: relative;
        left: 0;
        right: 0;

        &::after {
          content: "";
          height: 100%;
          background-color: $white;
          position: absolute;
          transition: width 0.1s linear;
          width: calc(100% * var(--line-progress));
        }

        @include media-breakpoint-up(lg) {
          height: 2px;
        }
      }
    }
  }
}
</style>
