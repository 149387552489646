<template>
  <section class="px-s pt-sm pb-ml pt-md-ml pb-md-l pt-lg-xl pb-lg-xxl" :class="$style.casesBox">
    <div class="container-md px-s px-md-0">
      <h2 class="mb-sm mb-md-l mb-lg-xl" :class="$style.title">Cases</h2>
      <slot />
    </div>
  </section>
</template>

<script lang="ts" setup></script>

<style module lang="scss">
.casesBox {
  background-color: $purple-900;
  color: $white;
  border-radius: $default-border-radius;

  .title {
    @extend %title-one-900-white;
  }
}
</style>
