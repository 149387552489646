<template>
  <AngledBox :class="$style.angleBox" class="mb-ml mb-lg-xl">
    <div :class="$style.grid" class="container-md px-s px-md-0 py-ml py-lg-l position-relative">
      <div></div>
      <div>
        <div :class="$style.icon">
          <SvgIcon icon="light-quotes" />
        </div>
        <figure class="m-0">
          <blockquote :class="[$style.quote, { 'mb-sm mb-lg-ml': slots.author }]">
            <slot />
          </blockquote>
          <figcaption v-if="author" :class="$style.author">
            {{ author }}
          </figcaption>
        </figure>
      </div>
    </div>
  </AngledBox>
</template>

<script lang="ts" setup>
import { useSlots } from "vue";
import AngledBox from "./AngledBox.vue";
import SvgIcon from "../SvgIcon.vue";

const props = withDefaults(
  defineProps<{
    author?: string;
  }>(),
  {},
);

const slots = useSlots();
</script>
<style module lang="scss">
.angleBox {
  --angled-box-color: #{$purple-700};

  .grid {
    display: grid;

    @include media-breakpoint-up(md) {
      grid-template-columns: 1fr 8fr 3fr;
    }

    .quote {
      @extend %text-one-900-ochre-500;
    }
    .author {
      @extend %text-three-ochre-500;
    }
    .icon {
      position: absolute;
      top: 0;
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 48px;
      height: 48px;
      background-color: $white;
      border-radius: 50%;

      svg {
        width: 32px;
        height: 32px;
      }

      @include media-breakpoint-up(lg) {
        width: 82px;
        height: 82px;

        svg {
          width: 56px;
          height: 56px;
        }
      }
    }
  }
}
</style>
