<template>
  <img :src="image.sizes?.xs?.url ? imgUrl(image.sizes.xs.url) : undefined" :srcSet="srcSet" :sizes="sizes" />
</template>

<script lang="ts" setup>
import { computed } from "#imports";
import { imgUrl } from "~/utils/imageUrl";
import type { SizedImageFragment } from "../../../graphql/graphql";

type Breakpoint = "md" | "lg" | "xl";
type ImageSize = "xs" | "md" | "lg" | "res_hd" | "res_fullhd" | "res_2k" | "res_4k";

type ImageSizing = [Breakpoint | null, ImageSize];

const props = withDefaults(
  defineProps<{
    image: SizedImageFragment;
    sizes: ImageSizing[];
  }>(),
  {},
);

const breakpointMap: {
  [key in Breakpoint]: number;
} = {
  md: 768,
  lg: 1440,
  xl: 1920,
};

const sizeMap: {
  [key in ImageSize]: number;
} = {
  res_4k: 3840,
  res_2k: 2048,
  res_fullhd: 1920,
  res_hd: 720,
  lg: 1168,
  md: 640,
  xs: 278,
};

const srcSet = computed(() => {
  return Object.entries(sizeMap)
    .map(([key, size]) => {
      return `${imgUrl(props.image.sizes?.[key as ImageSize]?.url ?? undefined)} ${size}w`;
    })
    .join(", ");
});

const sizes = computed(() => {
  return props.sizes
    .map((s) => {
      const breakpoint = s[0] ? breakpointMap[s[0]] : null;
      const size = sizeMap[s[1]];
      if (!breakpoint) {
        return `${size}px`;
      }
      return `(max-width: ${breakpoint}px) ${size}px`;
    })
    .join(",");
});
</script>

<style module lang="scss"></style>
