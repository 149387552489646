<template>
  <div :class="$style.dropCapText">
    <slot />
  </div>
</template>

<script lang="ts" setup></script>

<style module lang="scss">
.dropCapText {
  p:last-of-type {
    margin-bottom: 0;
  }

  p:first-of-type::first-letter {
    font-size: 30px;
    line-height: 0;

    @include media-breakpoint-up(md) {
      font-size: 34px;
    }
    @include media-breakpoint-up(lg) {
      font-size: 46px;
    }
  }
}
</style>
