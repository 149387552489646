import { getConfig } from "pulls-website-core";

const config = getConfig(process.env);

export function imgUrl(relativeUrl?: string) {
  if (!relativeUrl) {
    return "";
  }
  if (!config.mediaPrefixUrl) {
    return relativeUrl;
  }
  return `${config.mediaPrefixUrl}${relativeUrl}`;
}
