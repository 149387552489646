<template>
  <div>
    <NuxtLayout>
      <template v-for="component in contentComponents">
        <ContentComponentRenderer root :component="component" />
      </template>
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { computed, useAnchorNavItems, useAsyncQuery, useHead, useRoute, useSeoMeta, watch } from "#imports";
import { CurrentPageDocument } from "../graphql/graphql";
import { slugify } from "pulls-website-core";
import ContentComponentRenderer from "../components/ContentComponentRenderer.vue";

const route = useRoute();

let slug = route.params.slug[0] || "";

const query = await useAsyncQuery(CurrentPageDocument, {
  slug,
});

const page = computed(() => query.data.value?.Pages.docs[0]);

const title = `${page.value?.meta.title} - Leif PULLS`;

useHead({
  title: title,
});

useSeoMeta({
  title: title,
  ogTitle: title,
  description: page.value?.meta.description,
  ogDescription: page.value?.meta.description,
});

const anchorNavItems = useAnchorNavItems();

anchorNavItems.value = {};

watch(
  page,
  () => {
    if (!page.value) {
      return;
    }
    const anchorItems = page.value.content
      .filter((c) => c.__typename === "AnchorBlock")
      .map((c) => ({
        name: c.name,
        slug: slugify(c.name),
      }));
    anchorNavItems.value[page.value.slug] = anchorItems;
  },
  {
    immediate: true,
    deep: true,
  },
);

const contentComponents = computed(() => page.value.content ?? []);
</script>

<style module lang="scss"></style>
