<template>
  <div ref="wrapperRef" :class="$style.angledBox">
    <svg :class="$style.svg" xmlns="http://www.w3.org/2000/svg">
      <path :class="$style.path" :d="svgPath" />
    </svg>
    <slot />
  </div>
</template>

<script lang="ts" setup>
import { useElementSize } from "@vueuse/core";
import { computed, ref } from "#imports";

const props = withDefaults(
  defineProps<{
    cornerPosition?: "topright" | "bottomleft";
  }>(),
  {
    cornerPosition: "topright",
  },
);

const wrapperRef = ref<HTMLDivElement>();

const { width, height } = useElementSize(wrapperRef);

const RADIUS = 4;
const ANGLE = 15;

const svgPath = computed(() => {
  const lineSegWidth = width.value - RADIUS * 2;
  const lineSegHeight = height.value - RADIUS * 2;
  const tan = Math.tan((ANGLE / 360) * Math.PI * 2);
  const offset = lineSegHeight * tan;
  const arcOffset = RADIUS * tan;

  if (props.cornerPosition === "bottomleft") {
    return `
m${RADIUS + offset},0
h${lineSegWidth - offset} 
a${RADIUS},${RADIUS} 0 0 1 ${RADIUS},${RADIUS} 
l0,${lineSegHeight}
a${RADIUS},${RADIUS} 0 0 1 -${RADIUS},${RADIUS}
h${-lineSegWidth}
a${RADIUS},${RADIUS} 0 0 1 -${RADIUS},-${RADIUS + arcOffset} 
l${offset},${-lineSegHeight}
a${RADIUS},${RADIUS} 0 0 1 ${RADIUS},-${RADIUS - arcOffset} 
  `;
  }

  return `
m${RADIUS},0
h${lineSegWidth} 
a${RADIUS},${RADIUS} 0 0 1 ${RADIUS},${RADIUS + arcOffset} 
l${-offset},${lineSegHeight}
a${RADIUS},${RADIUS} 0 0 1 -${RADIUS},${RADIUS - arcOffset}
h${-lineSegWidth + offset}
a${RADIUS},${RADIUS} 0 0 1 -${RADIUS},-${RADIUS} 
l${0},${-lineSegHeight}
a${RADIUS},${RADIUS} 0 0 1 ${RADIUS},-${RADIUS} 
  `;
});
</script>

<style module lang="scss">
:root {
  --angled-box-color: #{$cyan-700};
}
.angledBox {
  position: relative;
  background-color: var(--angled-box-color);
  border-radius: $default-border-radius;

  .svg {
    display: none;
    overflow: visible;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: -1;
  }

  @include media-breakpoint-up(md) {
    background-color: unset;
    .svg {
      display: block;

      .path {
        fill: var(--angled-box-color);
      }
    }
  }
}
</style>
