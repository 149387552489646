<template>
  <div :class="$style.imageHeader" class="d-flex flex-column justify-content-end pe-auto">
    <SizedImage
      v-if="data.headerImage"
      :class="$style.image"
      :image="data.headerImage"
      :sizes="[
        ['md', 'lg'],
        ['xl', 'res_fullhd'],
        [null, 'res_2k'],
      ]"
    />
    {{ data.video }}
    <BackgroundVideo v-if="data?.video" muted autoplay :video="data.video" :class="$style.image" loop />
    <div :class="$style.overlay"></div>
    <div :class="$style.content" class="d-flex flex-column justify-content-end container-md px-s px-md-0 mb-sm mb-ml">
      <BubbleText class="mb-s">{{ data.headerBubbleText }}</BubbleText>
      <div :class="$style.titleGrid">
        <h1
          class="mb-m richtext"
          :class="[$style.title, { [$style.typewriterFx]: data.typewriterFx }]"
          v-html="data.typewriterFx ? typewriterHtml : data.headline"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import SizedImage from "~/components/content/SizedImage.vue";
import type { PageBlockFragment } from "../../../graphql/graphql";
import BubbleText from "../BubbleText.vue";
import { ref, computed, onMounted } from "vue";
import BackgroundVideo from "../BackgroundVideo.vue";

type ImageHeaderBlock = PageBlockFragment & { __typename: "ImageHeaderBlock" };

const props = withDefaults(
  defineProps<{
    data: ImageHeaderBlock;
  }>(),
  {},
);

const typewriterIndex = ref(0);
const typewriterInterval = ref<NodeJS.Timeout>();

const typewriterHtml = computed(() => {
  if (!props.data.headline) {
    return "";
  }
  const chars = props.data.headline.split("");
  const partA = chars.slice(0, typewriterIndex.value);
  const partB = chars.slice(typewriterIndex.value, chars.length);
  return `<span>${partA.join("")}</span>${partB.join("")}`;
});

const typewriterFx = () => {
  if (!process.client) {
    return;
  }
  typewriterIndex.value = 0;
  const textLength = props.data.headline?.length ?? 0;
  typewriterInterval.value = setInterval(() => {
    typewriterIndex.value += 1;
    if (typewriterIndex.value > textLength) {
      clearInterval(typewriterInterval.value);
    }
  }, 50);
};

onMounted(() => {
  typewriterFx();
});
</script>

<style module lang="scss">
.imageHeader {
  display: block;
  position: relative;
  width: 100%;
  height: calc(100dvh - var(--outer-border-width) * 1);
  border-radius: $default-border-radius;
  background-color: $purple-900;
  margin-bottom: var(--outer-border-width);
  overflow: hidden;
  border-radius: $default-border-radius;
  text-decoration: none;

  .image {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    pointer-events: none;
  }

  .overlay {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    object-fit: cover;
    pointer-events: none;
    background-color: rgba($black-one, 0.2);
  }

  .content {
    z-index: 1;

    .titleGrid {
      display: grid;
      grid-template-columns: 1fr;

      @include media-breakpoint-up(md) {
        grid-template-columns: 10fr 2fr;
      }

      .title {
        @extend %title-one-900-white;

        &.typewriterFx {
          position: relative;
          color: rgba(255, 255, 255, 0);

          @keyframes cursor-blink {
            0%,
            100%,
            50.01% {
              opacity: 0;
            }
            0.01%,
            50% {
              opacity: 1;
            }
          }
          span {
            position: relative;
            color: rgba(255, 255, 255, 1);
            &::before {
              content: "│";
              display: inline-block;
              position: absolute;
              bottom: 0.2rem;
              right: -4px;
              width: 10px;
              display: block;
              animation: cursor-blink 1s linear infinite;

              @include media-breakpoint-up(md) {
                bottom: 0.3rem;
                right: 0;
              }
              @include media-breakpoint-up(lg) {
                bottom: 0.5rem;
                right: 8px;
              }
            }
          }
          &.end {
            span {
              &::before {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
</style>
