<template>
  <video ref="video" playsinline preload="auto" :poster="poster" :loop="loop">
    <client-only>
      <source v-if="nativeHlsSupport" :key="videoSrcHls" :src="videoSrcHls" type="application/x-mpegURL" />
      <source v-if="noHlsSupport" :src="videoSrc" type="video/mp4" />
    </client-only>
  </video>
</template>

<script lang="ts" setup>
import HLS from "hls.js";
import { computed, onMounted, ref, watch } from "vue";

import { useRuntimeConfig } from "#imports";

const props = withDefaults(
  defineProps<{
    video: string;
    poster?: string;
    playOnLoad?: boolean;
    loop?: boolean;
  }>(),
  {
    playOnLoad: false,
    poster: undefined,
    loop: false,
  },
);

const config = useRuntimeConfig();
const cdnURL = config.app.cdnURL;

const videoSrc = computed(() => `${cdnURL}/videos/${props.video}`);
const videoSrcHls = computed(() => `${cdnURL}/videos/hls/${props.video}_master.m3u8`);
const nativeHlsSupport = computed(() => video.value?.canPlayType("application/vnd.apple.mpegurl"));
const noHlsSupport = computed(() => !nativeHlsSupport.value && !HLS.isSupported());

const video = ref<HTMLMediaElement | null>(null);

let hls: HLS | null = null;

if (HLS.isSupported()) {
  hls = new HLS();

  hls.on(HLS.Events.MEDIA_ATTACHED, function () {
    console.debug("video and hls.js are now bound together !");
  });

  hls.on(HLS.Events.MANIFEST_PARSED, function (_event, data) {
    console.debug(`manifest loaded, found ${data.levels.length} quality level`);
  });
}

onMounted(() => {
  if (video.value) {
    if (HLS.isSupported()) {
      hls?.attachMedia(video.value);
    }
    console.debug("video attached, playing now.");
  }

  watch(
    videoSrcHls,
    async () => {
      if (HLS.isSupported()) {
        hls?.loadSource(videoSrcHls.value);
      }
      if (props.playOnLoad) {
        video.value?.load();
        await video.value?.play();
      }
    },
    { immediate: true },
  );
});

const el = computed(() => video.value);
const getCurrentTime = () => video.value?.currentTime ?? 0;

defineExpose({ el, getCurrentTime });
</script>

<style module lang="scss"></style>
